const initBlock = async function( $block ) {
	const InfiniteSliderModule = await import( '../modules/infiniteslider' );
	const InfiniteSlider = InfiniteSliderModule.default;

	const instagramGallery = ( $slider ) => {
		new InfiniteSlider( $slider, {
			'speed': 40,
			'direction': 'left',
			'pauseonhover': true,
			'responsive': false,
		} );
	};

	const $instagramSlider = $block.find( '.instagram-gallery' );
	if ( $instagramSlider ) {
		instagramGallery( $instagramSlider );
	}
};

themeUtils.loadBlock( initBlock, 'footer', '.page-footer' );
